import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import tw, { styled } from "twin.macro"

import Modal from "components/modal"
import YouTube from "components/youtube.js"

const StyleContainer = styled.div`
  ${tw`mx-auto m:max-w-m l:max-w-l flex flex-col items-center relative`}

  z-index: 1;

  .video {
    ${tw`w-full text-center flex flex-col items-center px-2`}

    .video-preview {
      ${tw`border-2 border-white border-opacity-10`}

      border-radius: 10px;
    }

    .gradient {
      ${tw`block`}

      border-radius: 8px;
      left: 2px;
      top: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);

      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
`

const VideoModal = ({
  className = "",
  data = {},
  autoplayHash = "",
  ...params
}) => {
  const [modal, setModal] = useState(false)
  const location = useLocation()

  useEffect(() => {
    // Open modal if hash matches provided id
    if (
      autoplayHash !== "" &&
      location.hash.length > 0 &&
      location.hash === `#${autoplayHash}`
    ) {
      setModal(true)
    }
  }, [location])

  let previewImage, previewVideo

  const { videoModalId: videoId, previewImageHandle, previewVideoHandle } = data
  const mediaBase = process.env.GATSBY_ASSETS_BASE_URL

  if (previewImageHandle) {
    previewImage = `${mediaBase}auto_image/resize=width:1200/compress/${previewImageHandle}`
  }

  if (previewVideoHandle) {
    previewVideo = `${mediaBase}${previewVideoHandle}`
  }

  if (!videoId) {
    return null
  }

  return (
    <StyleContainer className={className}>
      <div className="video">
        <YouTube
          videoId={videoId}
          preview={previewImage}
          videoPreview={previewVideo}
          {...params}
        />
      </div>
      {modal && (
        <Modal isVisible={modal} onClose={() => setModal(false)}>
          <div className="video-player h-full w-full m:px-16">
            <YouTube
              videoId={videoId}
              active={true}
              autoplay={true}
              fixAspectRatio={false}
            />
          </div>
        </Modal>
      )}
    </StyleContainer>
  )
}

export default VideoModal
