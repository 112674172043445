import React from "react"
import { motion, useSpring, useTransform } from "framer-motion"
import styled from "styled-components"

import CDLine from "images/measurement/line.png"

const Stack = styled.div`
  bottom: 20px;
  position: absolute;
  width: 100%;

  .cd-line {
    position: absolute;
    left: calc(50% - 22px);
    bottom: 80px;
  }

  .cd-stack {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 60px;

    &.cd-stack-0 {
      bottom: 60px;
      left: 2%;
    }
    &.cd-stack-1 {
      bottom: 52px;
    }
    &.cd-stack-2 {
      bottom: 78px;
    }
    &.cd-stack-3 {
      bottom: 103px;
    }
  }
`

export default ({ shown, layers, scrollX, vWidth }) => {
  const slide = (n = 1) => n * vWidth
  const showOn = s => [0, slide(s - 1), slide(s)]
  const layerSlides = [1, 3, 5, 7]
  const staticLayers = Array.from({ length: shown }, (v, i) => i)

  return (
    <Stack>
      {shown === 0 && (
        <motion.img
          className="cd-line"
          src={CDLine}
          height="318"
          width="45"
          style={{
            opacity: useSpring(
              useTransform(scrollX, [0, slide(0.9), slide(1)], [0, 0, 1]),
              {
                stiffness: 50,
                damping: 100,
              }
            ),
          }}
        />
      )}
      {shown > 0 && (
        <img className="cd-line" src={CDLine} height="318" width="45" alt="" />
      )}
      {shown > 0 &&
        staticLayers.map(i => (
          <img
            key={i}
            src={layers[i].featuredImage.url}
            className={`cd-stack cd-stack-${i}`}
            alt=""
          />
        ))}
      <motion.img
        src={layers[shown].featuredImage.url}
        className={`cd-stack cd-stack-${shown}`}
        style={{
          y: useSpring(
            useTransform(scrollX, showOn(layerSlides[shown]), [-200, -200, 0]),
            {
              stiffness: 200,
              damping: 100,
            }
          ),
          opacity: useSpring(
            useTransform(scrollX, showOn(layerSlides[shown]), [0, 0, 1]),
            {
              stiffness: 200,
              damping: 100,
            }
          ),
        }}
      />
    </Stack>
  )
}
