import React from "react"
import tw, { styled } from "twin.macro"

const Styles = styled.div`
  ${tw`w-full flex flex-col m:flex-row space-y-4 m:space-y-0 m:space-x-4 l:space-x-5`}

  &.academy {
    ${tw`mt-7`}
  }

  &.ad {
    ${tw`mt-32`}
  }

  .feature {
    ${tw`relative w-full m:w-1/3 flex flex-col items-center`}

    img {
      ${tw`w-full object-cover rounded-xl m:max-h-full`}

      max-height: 300px;

      @media (min-width: 640px) {
        min-height: 387px;
      }
    }

    .info {
      ${tw`absolute w-11/12 l:w-5/6 text-center`}

      top: 60%;

      @media (min-width: 1024px) {
        top: 70%;
      }

      h5 {
        ${tw`text-yellow font-extrabold text-xl l:text-2xl`}
      }

      p {
        ${tw`text-white text-sm l:text-base mt-2`}
      }
    }
  }
`

const DemandAcademyFeatures = ({ features, className }) => {
  return (
    <Styles className={className}>
      {features.map((feature, i) => (
        <div key={i} className="feature">
          <img alt="" src={feature.image} />
          <div className="info">
            <h5>{feature.title}</h5>
            <p>{feature.description}</p>
          </div>
        </div>
      ))}
    </Styles>
  )
}

export default DemandAcademyFeatures
