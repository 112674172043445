import React from "react"
import tw, { styled } from "twin.macro"

import Button from "components/button"
import Slant from "components/sections/slant"

const Style = styled.div`
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;

  .container {
    ${tw`px-2 pt-40 pb-32 flex flex-col items-center overflow-hidden mx-auto max-w-s m:max-w-m l:max-w-l`}
  }

  .copy {
    ${tw`m:w-3/4 l:w-1/2 text-center`}
  }

  p {
    ${tw`font-body text-white`}
    font-size: 13px;
    line-height: 25px;
    @media (min-width: 640px) {
      font-size: 15px;
      line-height: 27px;
    }
  }

  h2 {
    ${tw`py-2 pt-4 w-full font-display font-bold text-white text-center`}
    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }
    @media (min-width: 1024px) {
      font-size: 60px;
      line-height: 79px;
    }
  }
`

const SlantedCTA = ({ block, variant = "purple", ...rest }) => {
  return (
    <Slant {...rest}>
      <Style bg={block.featuredImage ? block.featuredImage.url : ""}>
        <div className="container">
          <h2 dangerouslySetInnerHTML={{ __html: block?.title }} />
          <div
            className="copy"
            dangerouslySetInnerHTML={{ __html: block?.mainContent?.html }}
          />
          {block.linkUrl && (
            <Button to={block.linkUrl} className={`my-4 glow ${variant}`}>
              {block.callToAction}
            </Button>
          )}
        </div>
      </Style>
    </Slant>
  )
}

export default SlantedCTA
