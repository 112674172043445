import React from "react"
import tw, { styled } from "twin.macro"
import { motion, useTransform, useScroll } from "framer-motion"

import Logo from "images/logos/demand-academy.svg"
import Button from "components/button"
import ConditionalLink from "components/conditional-link"

const Styles = styled.div`
  &.academy {
    ${tw`pt-24 m:pt-40 l:pt-44`}

    padding-bottom: 242px;

    @media (min-width: 640px) {
      padding-bottom: 295px;
    }

    @media (min-width: 1024px) {
      padding-bottom: 400px;
    }
  }

  .content {
    ${tw`text-white font-bold text-lg l:text-xl text-center mt-12`}

    @media (min-width: 1200px) {
      max-width: 896px;
    }
  }

  .logo {
    ${tw`w-11/12 m:w-2/3`}

    @media (min-width: 1024px) {
      width: 615px;
    }
  }

  a {
    ${tw`w-full text-center`}
  }

  .cta {
    ${tw`mt-12`}

    width: 204px;
  }
`

const DemandAcademyHeader = ({
  page,
  className,
  onClick,
  logoTo,
  ctaTo,
  parallax = true,
}) => {
  // staggered fade in animations
  const headerList = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.15,
        duration: 0.5,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }
  const headerItem = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.75, ease: "easeOut" },
    },
    hidden: { opacity: 0, y: 30 },
  }

  // parallax
  const { scrollY } = useScroll()
  const y1 = useTransform(scrollY, [0, 300], [0, -100])
  const y2 = useTransform(scrollY, [0, 300], [0, -90])
  const y3 = useTransform(scrollY, [0, 300], [0, -80])

  return (
    <Styles className={className}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={headerList}
        className="section flex flex-col items-center"
      >
        <ConditionalLink to={logoTo}>
          <motion.img
            className="inline-block logo"
            src={Logo}
            variants={headerItem}
            style={{ y: parallax ? y1 : undefined }}
          />
        </ConditionalLink>
        <motion.div
          className="content"
          dangerouslySetInnerHTML={{
            __html: `<div>${page.headerContent?.html}</div>`,
          }}
          variants={headerItem}
          style={{ y: parallax ? y2 : undefined }}
        />
        <motion.div
          className="cta"
          variants={headerItem}
          style={{ y: parallax ? y3 : undefined }}
        >
          <Button onClick={onClick} to={ctaTo} className="red w-full">
            Start Learning
          </Button>
        </motion.div>
      </motion.div>
    </Styles>
  )
}

export default DemandAcademyHeader
