import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import { Helmet } from "react-helmet"
import tw, { styled } from "twin.macro"
import { useMediaQuery } from "react-responsive"

import { brandBlock } from "utils/brand"
import Layout from "components/layout"
import SEO from "components/seo"
import VideoModal from "components/sections/video-modal"
import ContentDemand from "components/sections/content-demand"
import ContentDemandMobile from "components/sections/content-demand-mobile"
import SlantedCTA from "components/sections/slanted-cta"
import TopoBG from "images/bg/topo.jpg"
import HighlightsBG from "images/bg/demand-academy-body.jpg"
import IndustryBubbles from "components/sections/industry-bubbles"
import DemandGlobe from "components/sections/demand-globe"
import DemandAcademyAd from "components/sections/demand-academy-ad"
import Demand360Ad from "components/demand360-ad"
import SlantedSection from "components/sections/slant"

import { getSharePreviewUrls } from "utils/share-preview"

const PageStyles = styled.div`
  img.hero {
    @media (max-width: 640px) {
      width: 240vw;
      max-width: initial;
      margin-left: -120vw;
      left: 50%;
      position: relative;
      margin-bottom: -14vw;
    }
  }

  .col {
    ${tw`w-full m:w-1/2 l:w-1/4 px-2 pt-8 flex flex-col items-center text-white font-body`}
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  p {
    ${tw`font-body text-white`}
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 25px;
    @media (min-width: 640px) {
      font-size: 15px;
      line-height: 27px;
      margin-bottom: 27px;
    }
  }

  h1,
  h2 {
    ${tw`py-2 w-full font-display font-bold text-white`}

    font-size: 40px;
    line-height: 50px;

    @media (min-width: 640px) {
      font-size: 50px;
      line-height: 62px;
    }

    @media (min-width: 1024px) {
      font-size: 60px;
      line-height: 75px;
      margin-left: -200px;
      margin-right: -200px;
      width: 1114px;
    }
  }

  h3 {
    ${tw`font-display font-bold text-yellow mt-4 l:mt-0`}
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    text-align: center;
  }

  h4 {
    ${tw`text-yellow font-display font-bold`}
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 640px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  h5 {
    ${tw`font-display font-bold text-white`}
    font-size: 13px;
    line-height: 25px;
    @media (min-width: 640px) {
      font-size: 15px;
      line-height: 27px;
    }
  }
`

const Topo = styled.div`
  background: linear-gradient(180deg, #0d0023 0%, rgba(13, 0, 35, 0) 9.64%),
    url(${TopoBG});
  background-repeat: repeat-y;
  background-size: 500% auto;
  background-position: center top;

  @media (min-width: 640px) {
    background-size: 200% auto;
    background-position: -50px top;
  }

  @media (min-width: 1200px) {
    background-size: contain;
  }
`

const Highlights = styled.div`
  ${tw`mt-32 m:mt-12 l:-mt-24 l:relative l:z-10`}

  background: linear-gradient(180deg,
      #000000 0%, rgba(0, 0, 0, 0) 30.02%,
      rgba(13, 0, 35, 0) 83.48%,
      #0D0023 100%
    ),
    url(${HighlightsBG});

  background-size: cover;
  background-color: #0d0023;

  @media (min-width: 640px) {
    background-repeat: no-repeat;
    background-position: center top;
  }

  @media (min-width: 1200px) {
    background-size: 100%;
  }

  h3 {
    ${tw`mt-4`}
  }
`

export default ({ data }) => {
  const [isTablet, setIsTablet] = useState(false)
  const isTabletBreakpoint =
    typeof window !== undefined && useMediaQuery({ minWidth: 640 })

  const { allTvDemandJson, gcms } = data
  const { page } = gcms
  const blocks = page.contentBlocks.map((cb) => brandBlock(cb))
  const contentDemandLayers = blocks.filter(
    (b) => b.identifier === "content-demand-layer"
  )
  const cols = blocks.filter((b) => b.order > 0 && b.order < 100)
  const footer = blocks.find((b) => b.identifier === "measurement-footer")

  useEffect(() => {
    setIsTablet(isTabletBreakpoint)
  }, [isTabletBreakpoint, setIsTablet])

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript" id="geoip" src="/js/geo.js"></script>
      </Helmet>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <PageStyles>
        <div className="pt-20 m:pt-40 relative overflow-hidden">
          <DemandGlobe />
          <Highlights>
            <div className="max-w-s mx-auto m:max-w-m l:max-w-l flex flex-col items-center">
              <div className="-mx-2 flex flex-wrap">
                {cols.map((c, i) => (
                  <div className="col" key={i}>
                    {c.icon && <img src={c.icon.url} alt={c.title} />}
                    <h3>{c.title}</h3>
                    <div
                      className="body"
                      dangerouslySetInnerHTML={{
                        __html: c?.mainContent?.html,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <VideoModal
              autoplayHash="demo"
              className="mt-16 m:mt-24 l:mt-32 relative"
              caption={"What is demand?"}
              data={page.extraData}
            />
          </Highlights>
        </div>
      </PageStyles>
      {allTvDemandJson?.nodes[0]?.markets?.length > 1 && (
        <div id="content-demand" className="-mt-32 m:-mt-16">
          {!isTablet && <ContentDemandMobile layers={contentDemandLayers} />}
          {isTablet && <ContentDemand layers={contentDemandLayers} />}
        </div>
      )}
      <DemandAcademyAd />
      <Topo>
        <div className="px-2 py-16 m:py-32 flex flex-col justify-center items-center content-center mx-auto max-w-s m:max-w-m l:max-w-l">
          <IndustryBubbles />
        </div>
      </Topo>
      <SlantedSection>
        <Demand360Ad className="measurement" gradientClass="measurement" />
      </SlantedSection>
      {footer && <SlantedCTA variant="purple" block={footer} />}
    </Layout>
  )
}

export const query = graphql`
  query {
    allTvDemandJson {
      nodes {
        markets {
          country_name
        }
      }
    }
    gcms {
      page(where: { path: "/measurement" }) {
        ...PageData
      }
    }
  }
`
