import React, { useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw from "twin.macro"

import DemandAcademyFeatures from "components/sections/demand-academy-features"
import DemandAcademyHeader from "components/sections/demand-academy-header"
import BasicBackgroundSection from "components/basic-background-section"
import BgBody from "images/bg/demand-academy-ad.jpg"

const DemandAcademyAd = () => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        adBlock: contentBlocks(
          where: { identifier: "demand-academy-ad" }
          first: 1
        ) {
          headerContent {
            html
          }
          extraData
        }
      }
    }
  `)
  const { gcms } = data
  const adBlock = gcms.adBlock[0]

  return (
    <BasicBackgroundSection
      image={BgBody}
      color="#0D0023"
      className="body"
      bgPosition="center top"
      size="cover"
      repeat="no-repeat"
    >
      <div className="mx-auto px-2 m:px-4 l:px-0 l:max-w-l py-24 m:py-32">
        <DemandAcademyHeader
          className="ad"
          page={adBlock}
          logoTo="/academy"
          ctaTo="/academy/#resources"
          parallax={false}
        />
        {adBlock.extraData?.features && (
          <DemandAcademyFeatures
            className="ad"
            features={adBlock.extraData.features}
          />
        )}
      </div>
    </BasicBackgroundSection>
  )
}

export default DemandAcademyAd
