import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import DemandGlobeContent from "./demand-globe-content"

const Styles = styled.div`
  ${tw`flex flex-col relative`}

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 17.09%, #000000 51.88%, rgba(0, 0, 0, 0) 100%);

  @media (min-width: 640px) {
    min-height: 604px;
  }

  @media (min-width: 1200px) {
    min-height: 828px;
  }

  .text {
    ${tw`flex flex-col items-center text-center mx-auto px-4 m:px-0 m:max-w-m l:max-w-l relative`}

    z-index: 4;

    .content {
      ${tw`text-white`}
    }
  }
`

const DemandGlobe = () => {
  const data = useStaticQuery(graphql`
    query DemandGlobe {
      gcms {
        demandGlobe: contentBlocks(where: { identifier: "demand-globe" }) {
          headerContent {
            html
          }
        }
      }
    }
  `)

  const { gcms } = data
  const { demandGlobe } = gcms
  const { headerContent } = demandGlobe[0] || {}

  return (
    <Styles>
      <div className="text">
        {headerContent && (
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: headerContent.html,
            }}
          />
        )}
      </div>
      <DemandGlobeContent />
    </Styles>
  )
}

export default DemandGlobe
