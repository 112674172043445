import React from "react"
import { Link } from "gatsby"

const ConditionalLink = ({ to, children, ...params }) => {
  return (
    <>
      {to && (
        <Link to={to} {...params}>{children}</Link>
      )}
      {! to && (
        <>{children}</>
      )}
    </>
  )
}

export default ConditionalLink
