export function posterUrl(parrotId, size = "EXTRA_LARGE") {
  const templateUrl =
    process.env.GATSBY_POSTER_URL_TEMPLATE ||
    "https://cdn-fastly.parrotanalytics.com/images/poster-hyphen/{{parrotId}}.jpg?class={{size}}"

  return templateUrl.replace("{{parrotId}}", parrotId).replace("{{size}}", size)
}

export function backgroundUrl(parrotId, size = "LARGE") {
  const templateUrl =
    process.env.GATSBY_BACKGROUND_URL_TEMPLATE ||
    "https://cdn-fastly.parrotanalytics.com/images/background-hyphen/{{parrotId}}.jpg?class={{size}}"

  return templateUrl.replace("{{parrotId}}", parrotId).replace("{{size}}", size)
}
