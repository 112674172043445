import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import tw, { styled } from "twin.macro"
import {
  motion,
  useSpring,
  useTransform,
  useElementScroll,
  useScroll,
} from "framer-motion"
import moment from "moment"
import ReactTooltip from "react-tooltip"

import Button from "components/button"
import ContentDemandStack from "components/content-demand-stack"
import PosterReveal from "components/poster-reveal"
import DownArrow from "images/icons/arrow-down-white.svg"
import CDLine from "images/measurement/line.png"
import CDLineRed from "images/measurement/red-line.png"
import CDLineGreen from "images/measurement/green-line.png"
import HDivider from "images/measurement/horizontal-line.svg"
import SearchBar from "images/measurement/google-search-bar.png"
import iMacScreens from "images/measurement/iMac_Screens@2x.png"
import LogoTransition1 from "images/measurement/logo-transition-1.png"
import LogoTransition2 from "images/measurement/logo-transition-2.png"
import TopoBG from "images/bg/topo.jpg"
import InfoIcon from "images/icons/information-grey.svg"
import ArrowContainer from "components/arrow-container"
import PageArrow from "components/page-arrow"
import Demand360Logo from "images/logos/demand-360-logo-dark.svg"

const flags = [
  "ar",
  "au",
  "br",
  "br",
  "ca",
  "cl",
  "co",
  "de",
  "es",
  "fr",
  "gb",
  "in",
  "it",
  "mx",
  "no",
  "nz",
  "se",
  "tr",
  "us",
]

const Styles = styled.div`
  ${tw`text-grey-90 font-body overflow-hidden relative m:hidden pt-64 pb-16`}

  background: linear-gradient(180deg, #000000 65px, rgba(0, 0, 0, 0) 300px),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 90%, #000000 100%),
    url(${TopoBG});
  background-size: cover;

  h1,
  h2,
  h3,
  h4 {
    ${tw`font-display font-bold text-white text-center`}
  }

  h2 {
    font-size: 30px;
    line-height: 35px;
  }

  h3 {
    font-size: 17px;
    line-height: 17px;
  }

  h4 {
    font-size: 14px;
    line-height: 26px;
  }

  p {
    font-size: 12px;
    line-height: 22px;
  }

  ul {
    list-style: disc;
    margin-top: 0.5rem;
    padding-left: 1rem;
  }

  .header {
    height: 65px;
    overflow: visible;
    position: relative;
    z-index: 1;

    h2 {
      position: absolute;
      width: 100%;
      left: 0;
      top: 30px;
    }
  }

  .scroller {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  .cd-line {
    position: absolute;
    left: calc(50% - 22px);
    bottom: 80px;

    &.red {
      bottom: 140px;
    }
  }

  .cd-stack {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 60px;
  }

  .search-bar {
    ${tw`text-grey-10`}
    margin-left: -5px;
    margin-top: 15px;
    font-size: 11px;
    line-height: 11px;

    .search-term {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      height: 12px;
      margin-left: 27px;
      margin-top: -26px;
      overflow: hidden;
      padding-top: 1px;
      text-align: left;
      text-overflow: ellipsis;
      width: 153px;
    }
  }

  .platform-logos {
    position: absolute;
    width: 100%;
    height: 100vw;
    bottom: 30px;

    > div {
      ${tw`absolute flex flex-row z-20`}
      width: 16%;

      &.linear {
        left: 29%;
        top: 23%;
      }

      &.avod {
        left: 51%;
        top: 35.5%;
      }

      &.pay {
        left: 63%;
        top: 23%;
      }

      &.svod {
        left: 15%;
        top: 35.5%;
      }

      > div {
        height: 100%;
        margin-right: 4px;
        max-width: unset;
        border-radius: 4px;
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.24);
        overflow: hidden;
      }
    }
  }

  .nav-dots {
    ${tw`flex flex-row justify-center`}
    position: relative;
    top: -50px;
    width: 100%;
    z-index: 100;

    .nav-dot {
      background: rgba(216, 216, 216, 0.5);
      height: 14px;
      width: 14px;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 100px;

      &.active {
        background: #d8d8d8;
        position: absolute;
        left: calc(50% - 144px);
      }
    }
  }

  .tv-demand-posters {
    ${tw`mx-auto max-w-s m:max-w-m l:max-w-l flex -mx-2`}
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;

    #poster-0 {
      padding-left: 75px;
    }
  }
`

const Slide = styled.div`
  height: calc(100vh-115px);
  min-height: 550px;
  width: 100vw;
  min-width: 100vw;
  scroll-snap-align: start;
  position: relative;

  ${tw`mx-auto pb-3 text-white flex flex-col items-center`}

  font-size: 12px;
  line-height: 22px;
  margin-top: 25px;
  text-align: center;

  h3,
  h4 {
    ${tw`font-display font-bold text-white`}
    font-size: 12px;
    line-height: 22px;
  }

  ul {
    list-style-position: inside;
    padding-left: 0;

    li > div {
      display: inline;
    }
  }

  .cd-header {
    padding-left: 1rem;
    padding-right: 1rem;

    &.red {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(227, 40, 76, 0.4) 100%
      );
      padding-bottom: 20px;
    }

    &.green {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(94, 249, 154, 0.4) 100%
      );
      padding-bottom: 20px;
    }
  }

  .logo-transition {
    position: absolute;
    width: 100%;
    bottom: -10px;
    z-index: 100;
  }

  &#slide-11 {
    .imac-screens {
      position: absolute;
      width: 80%;
      bottom: calc(-36vw + 260px);
      left: -38vw;
    }

    .logo-section {
      bottom: 180px;
      position: absolute;
    }

    .cd-content {
      bottom: 90px;
      position: absolute;
      width: 90%;
    }
  }

  &#slide-14 {
    p,
    h4,
    li {
      font-size: 13px;
    }
  }

  .red-gradient-55 {
    background: linear-gradient(
      55deg,
      rgba(227, 40, 76, 0.32) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .red-gradient-325 {
    background: linear-gradient(
      325deg,
      rgba(227, 40, 76, 0.32) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .logo {
    ${tw`font-display font-bold text-white`}
    font-size: 34px;
    line-height: 34px;
    border-right: 1px solid #444444;
    padding-right: 16px;
  }

  .tagline {
    ${tw`font-display font-bold`}
    padding-left: 16px;
    color: #d5d5d5;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 17px;
  }

  &.crop {
    padding-top: 20px;
  }
`

const ShowCover = styled.div`
  ${tw`relative`}
  background-image: ${(props) =>
    props?.posters?.small ? `url(${props.posters.small})` : ""};
  @media (min-width: 640px), (-webkit-min-device-pixel-ratio: 2) {
    background-image: ${(props) =>
      props?.posters?.medium ? `url(${props.posters.medium})` : ""};
  }
  background-size: cover;
  height: 216px;
  scroll-snap-align: center;
  width: 146px;
`

const ShowBackground = styled.div`
  ${tw`absolute top-0 left-0 flex flex-col items-start justify-end`}
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
  height: 216px;
  width: 146px;

  .show-title {
    ${tw`font-display text-white py-1 m:py-2 px-3`}
    font-size: 11px;
    line-height: 17px;
    text-align: left;
  }

  .growth {
    ${tw`font-secondary text-springgreen-light px-3 pb-3`}
    font-size: 24px;
    line-height: 24px;
  }
`

const ShowCard = ({ show }) => {
  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
      decimals
    )
  }

  return (
    <ShowCover posters={show.posters}>
      <a href={show.seo_page} rel="noopener noreferrer" target="_blank">
        <ShowBackground>
          <h4 className="show-title">{show.title}</h4>
          <span className="growth">+{round(show.demand_growth, 1)}%</span>
        </ShowBackground>
      </a>
    </ShowCover>
  )
}

const MarketSelect = styled.select`
  ${tw`font-display text-white`}
  display: block;

  font-size: 16px;
  line-height: 16px;
  padding: 12px;
  padding-left: 50px;
  padding-right: 40px;
  padding-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #b070fd;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #281040;
  background-repeat: no-repeat, no-repeat;
  background-position: right 12px top 50%, left 10px top 50%;
  background-size: 20px auto, 28px 20px;
  &:focus {
    outline: none;
  }

  &.IN {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/in.png);
  }
  &.IT {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/it.png);
  }
  &.FR {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/fr.png);
  }
  &.AR {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/ar.png);
  }
  &.AU {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/au.png);
  }
  &.ES {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/es.png);
  }
  &.BR {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/br.png);
  }
  &.BR {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/br.png);
  }
  &.CA {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/ca.png);
  }
  &.DE {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/de.png);
  }
  &.MX {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/mx.png);
  }
  &.GB {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/gb.png);
  }
  &.US {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/us.png);
  }
  &.NZ {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/nz.png);
  }
  &.CO {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/co.png);
  }
  &.TR {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/tr.png);
  }
  &.NO {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/no.png);
  }
  &.SE {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/se.png);
  }
  &.CL {
    background-image: url(${DownArrow}),
      url(https://parrot-portalassets.s3.amazonaws.com/flags/cl.png);
  }
`

export default ({ layers }) => {
  const showLimit = 6
  const data = useStaticQuery(graphql`
    query {
      allTvDemandJson {
        nodes {
          markets {
            country_name
            country_iso
            slug
            market_name
            heating_up {
              demand_growth
              posters {
                small
                medium
              }
              title
              seo_page
              parrot_id
            }
          }
        }
      }
      gcms {
        markets {
          isoCode
          linearPlatforms(orderBy: topPlatform_DESC) {
            name
            smallLogo {
              url
            }
          }
          payPlatforms(orderBy: topPlatform_DESC) {
            name
            smallLogo {
              url
            }
          }
          svodPlatforms(orderBy: topPlatform_DESC) {
            name
            smallLogo {
              url
            }
          }
          avodPlatforms(orderBy: topPlatform_DESC) {
            name
            smallLogo {
              url
            }
          }
        }
        websiteSetting(where: { name: "Measurement page localization" }) {
          jsonValue
        }
      }
    }
  `)
  const markets = data.allTvDemandJson.nodes[0].markets
  const [currentMarket, setMarket] = useState(markets[0])
  const shows = currentMarket.heating_up.slice(0, showLimit)
  const [postDate, setPostDate] = useState("")
  const { websiteSetting } = data.gcms
  const [vWidth, setVWith] = useState(0)
  const [leftArrow, setLeftArrow] = useState(false)
  const [rightArrow, setRightArrow] = useState(true)
  const scroller = useRef()
  const { scrollX } = useScroll({ container: scroller })
  const posterScroller = useRef()
  const { scrollX: posterScrollX } = useScroll({ container: posterScroller })
  const stiffness = 300
  const damping = 50

  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      setVWith(window.innerWidth)
    }
  }, [setVWith])

  const switchMarket = (market) => {
    const marketPlatforms = data.gcms.markets.find(
      (p) => p.isoCode === market.country_iso
    )
    if (marketPlatforms) {
      market.platforms = {
        linear: marketPlatforms.linearPlatforms,
        svod: marketPlatforms.svodPlatforms,
        avod: marketPlatforms.avodPlatforms,
        pay: marketPlatforms.payPlatforms,
      }
    }
    setMarket(market)
    if (posterScroller && posterScroller.current) {
      posterScroller.current.scrollTo(0, 0)
    }
  }

  const selectMarket = (e) => {
    const currentSlug = e.currentTarget.value
    switchMarket(markets.find((m) => m.country_iso === currentSlug))
  }

  useEffect(() => {
    setPostDate(`${moment(new Date()).format("MMMM D")} at 3:40PM`)
  }, [setPostDate])

  useLayoutEffect(() => {
    const defaultMarket =
      markets.find((m) => m.country_iso === websiteSetting.jsonValue.default) ||
      markets[0]
    if (
      typeof window !== `undefined` &&
      window.country &&
      window.country.code !== null
    ) {
      let countryCode = window.country.code
      // Check if there is a country code override in the settings
      if (websiteSetting?.jsonValue[window.country.code]) {
        countryCode = websiteSetting?.jsonValue[window.country.code]
      }
      const selectedMarket = markets.find((m) => m.country_iso === countryCode)
      if (selectedMarket) {
        switchMarket(selectedMarket)
      } else {
        switchMarket(defaultMarket)
      }
    } else {
      switchMarket(defaultMarket)
    }
  }, [])

  // Animations and transitions
  const tvDemandOpacity = useSpring(
    useTransform(posterScrollX, [0, 916], [0, 1]),
    { stiffness: stiffness, damping }
  )

  useEffect(() => {
    tvDemandOpacity.onChange((v) => {
      setLeftArrow(v > 0.1)
      setRightArrow(v < 0.75)
    })
  }, [tvDemandOpacity])

  function posterScrollLeft() {
    if (posterScroller && posterScroller.current) {
      posterScroller.current.scrollBy({
        top: 0,
        left: -154,
        behavior: "smooth",
      })
    }
  }

  function posterScrollRight() {
    if (posterScroller && posterScroller.current) {
      posterScroller.current.scrollBy({
        top: 0,
        left: 154,
        behavior: "smooth",
      })
    }
  }

  function scrollTo(i) {
    if (scroller.current) {
      scroller.current.scrollTo({
        top: 0,
        left: slide(i),
        behavior: "smooth",
      })
    }
  }

  const slides = Array.from({ length: 16 }, (v, i) => i)
  const slide = (n = 1) => n * vWidth
  const showOn = (s) => [0, slide(s), slide(s + 1)]

  return (
    <Styles>
      <Helmet>
        {flags.map((f) => (
          <link
            key={f}
            rel="preload"
            href={`https://parrot-portalassets.s3.amazonaws.com/flags/${f}.png`}
            as="image"
          />
        ))}
      </Helmet>
      <div className="header">
        <h4>The two layers of the demand economy</h4>
        <motion.h2
          style={{
            opacity: useSpring(useTransform(scrollX, showOn(9), [1, 1, 0]), {
              stiffness: stiffness,
              damping,
            }),
          }}
        >
          <span className="text-dodgeblue-light">Content Demand</span>
        </motion.h2>
        <motion.h2
          style={{
            opacity: useSpring(useTransform(scrollX, showOn(9), [0, 0, 1]), {
              stiffness: stiffness,
              damping,
            }),
          }}
        >
          <span className="text-dodgeblue-light">Demand &amp;</span>
          <br />
          <span className="text-neonred">Monetization</span>
        </motion.h2>
      </div>
      <div className="scroller" ref={scroller}>
        <Slide id="slide-tv">
          <div className="cd-header">
            Billions of people interact with content online on billions of
            screens and devices. The time has come for panel measurement to give
            way to empirical measurement.
          </div>
          <img src={HDivider} height="1" width="363" alt="" className="my-4" />

          <div className="tv-demand">
            <div className="relative w-full overflow-hidden">
              <div className="absolute top-0 left-0 w-full arrows ">
                <ArrowContainer>
                  <PageArrow
                    dir="left"
                    enabled={leftArrow}
                    onClick={posterScrollLeft}
                  />
                  <PageArrow
                    dir="right"
                    enabled={rightArrow}
                    onClick={posterScrollRight}
                  />
                </ArrowContainer>
              </div>

              <div className="tv-demand-posters" ref={posterScroller}>
                {shows.map((show, idx) => (
                  <motion.div
                    key={idx}
                    className={`px-2 ${idx === 5 ? "pr-16" : ""}`}
                    id={`poster-${idx}`}
                    style={{
                      opacity:
                        idx === 0
                          ? useSpring(
                              useTransform(posterScrollX, [0, 50], [1, 0.4]),
                              { stiffness: stiffness, damping }
                            )
                          : useSpring(
                              useTransform(
                                posterScrollX,
                                [
                                  0,
                                  154 * idx - 56,
                                  154 * idx - 36,
                                  154 * idx + 75,
                                  154 * idx + 76,
                                ],
                                [0.4, 0.4, 1, 1, 0.4]
                              ),
                              { stiffness: stiffness, damping }
                            ),
                    }}
                  >
                    <ShowCard show={show} />
                  </motion.div>
                ))}
              </div>
            </div>
            <div className="text-white text-xs text-center font-body pt-8 market-select">
              Show me the fastest growing shows in
              {currentMarket.market_name.substr(0, 3) === "the" ? " the" : ""}
              <div className="mx-2 my-2 flex flex-col items-center">
                <MarketSelect
                  className={currentMarket.country_iso}
                  onChange={selectMarket}
                  value={currentMarket.country_iso}
                >
                  {markets.map((market, idx) => (
                    <option key={idx} value={market.country_iso}>
                      {market.country_name}
                    </option>
                  ))}
                </MarketSelect>
              </div>
              right now
              <img
                src={InfoIcon}
                className="hidden m:inline-block mx-2 info-icon"
                height="14px"
                width="14"
                alt=""
                data-tip="A selection of the top TV shows in the selected market with the highest demand growth rate over the last 30 days, compared to the prior 30 days, based on Parrot Analytics’ global cross-platform TV demand data. Only the top 200 titles in the market are considered for this ranking."
              />
              <ReactTooltip
                className="font-body text-white bg-black w-64 rounded opacity-1"
                delayHide={200}
                effect="solid"
                place="top"
                overridePosition={({ left, top }) => {
                  if (top < 100) {
                    top = top + 300
                  }
                  return { top, left }
                }}
              />
            </div>
          </div>
        </Slide>
        <Slide id="slide-0">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[0].headerContent.html }}
          />
          <ContentDemandStack
            shown={0}
            layers={layers}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-1">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[0].mainContent.html }}
          />
          <div className="search-bar">
            <img alt="" src={SearchBar} width="236" height="36" />
            <div className="search-term pointer-events-none">
              {shows[0].title}
            </div>
          </div>
          <PosterReveal
            pos={1}
            market={currentMarket}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-2">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[1].headerContent.html }}
          />
          <ContentDemandStack
            shown={1}
            layers={layers}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-3">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[1].mainContent.html }}
          />
          <PosterReveal
            pos={3}
            market={currentMarket}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-4">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[2].headerContent.html }}
          />
          <ContentDemandStack
            shown={2}
            layers={layers}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-5">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[2].mainContent.html }}
          />
          <PosterReveal
            pos={5}
            market={currentMarket}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-6">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[3].headerContent.html }}
          />
          <ContentDemandStack
            shown={3}
            layers={layers}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-7">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[3].mainContent.html }}
          />
          <PosterReveal
            pos={7}
            market={currentMarket}
            scrollX={scrollX}
            vWidth={vWidth}
          />
        </Slide>
        <Slide id="slide-8">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[4].headerContent.html }}
          />
          <img
            className="cd-line"
            src={CDLine}
            height="318"
            width="45"
            alt=""
          />
          <img
            src={layers[4].featuredImage.url}
            className="cd-stack cd-stack-4"
            alt={layers[4].featuredImage.altText}
          />
        </Slide>
        <Slide id="slide-9" className="crop">
          <div
            className="cd-header red"
            dangerouslySetInnerHTML={{ __html: layers[5].headerContent.html }}
          />
          <img
            className="cd-line red"
            src={CDLineRed}
            alt=""
            height="318"
            width="45"
          />
          <motion.img
            src={layers[5].featuredImage.url}
            className="cd-stack cd-stack-4"
            style={{
              x: useSpring(
                useTransform(
                  scrollX,
                  [0, slide(10), slide(11)],
                  [0, 0, vWidth / 3]
                ),
                { stiffness: stiffness, damping }
              ),
            }}
          />
          <motion.div
            className="platform-logos"
            style={{
              x: useSpring(
                useTransform(
                  scrollX,
                  [0, slide(10), slide(11)],
                  [0, 0, vWidth / 3]
                ),
                { stiffness: stiffness, damping }
              ),
            }}
          >
            {!!currentMarket.platforms
              ? Object.keys(currentMarket.platforms).map((s) => (
                  <div key={s} className={s}>
                    {currentMarket.platforms[s].map(
                      (l, i) =>
                        l.smallLogo && (
                          <div key={i}>
                            <img src={l.smallLogo.url} alt={l.name} />
                          </div>
                        )
                    )}
                  </div>
                ))
              : null}
          </motion.div>
        </Slide>
        <Slide id="slide-10" className="crop">
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[8].headerContent.html }}
          />
          <img
            src={LogoTransition1}
            alt="Parrot Analytics DEMAND360 logo"
            className="logo-transition"
          />
        </Slide>
        <Slide id="slide-11" className="crop">
          <motion.img
            alt="DEMAND360 LITE screenshot"
            src={iMacScreens}
            className="imac-screens"
            style={{
              transform: `translateX(${Math.round(
                -vWidth / 2.08
              )}px) translateY(${Math.round(vWidth / 2.78)}px)`,
              x: useSpring(
                useTransform(
                  scrollX,
                  [0, slide(11), slide(12)],
                  [0, 0, Math.round(vWidth / 2.08)]
                ),
                { stiffness: stiffness, damping }
              ),
              y: useSpring(
                useTransform(
                  scrollX,
                  [0, slide(11), slide(12)],
                  [0, 0, Math.round(-vWidth / 2.78)]
                ),
                { stiffness: stiffness, damping }
              ),
            }}
          />
          <div className="flex pb-2 border-grey-10 border-b logo-section">
            <div className="logo flex items-center demand360-brand">
              <img src={Demand360Logo} />
            </div>
            <div className="tagline">
              The global demand
              <br /> measurement platform
            </div>
          </div>
          <div
            className="cd-content"
            dangerouslySetInnerHTML={{ __html: layers[6].mainContent.html }}
          />
        </Slide>
        <Slide id="slide-12" className="crop">
          <div
            className="cd-header green"
            dangerouslySetInnerHTML={{ __html: layers[7].headerContent.html }}
          />
          <img
            className="cd-line red"
            alt=""
            src={CDLineGreen}
            height="318"
            width="45"
          />
          <motion.img
            src={layers[7].featuredImage.url}
            className="cd-stack cd-stack-4"
            style={{
              x: useSpring(
                useTransform(scrollX, showOn(13), [0, 0, vWidth / 3]),
                { stiffness: stiffness, damping }
              ),
            }}
          />
          <motion.div
            className="platform-logos"
            style={{
              x: useSpring(
                useTransform(scrollX, showOn(13), [0, 0, vWidth / 3]),
                { stiffness: stiffness, damping }
              ),
            }}
          >
            {!!currentMarket.platforms
              ? Object.keys(currentMarket.platforms).map((s) => (
                  <div key={s} className={s}>
                    {currentMarket.platforms[s].map(
                      (l, i) =>
                        l.smallLogo && (
                          <div key={i}>
                            <img src={l.smallLogo.url} alt={l.name} />
                          </div>
                        )
                    )}
                  </div>
                ))
              : null}
          </motion.div>
        </Slide>
        <Slide id="slide-13" className="crop">
          <motion.div
            className="red-gradient-325"
            style={{
              opacity: useSpring(
                useTransform(scrollX, [0, slide(14), slide(15)], [0, 0, 1]),
                { stiffness: stiffness, damping }
              ),
            }}
          />
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[9].headerContent.html }}
          />
          <img src={LogoTransition2} alt="" className="logo-transition" />
        </Slide>
        <Slide id="slide-14" className="crop">
          <motion.div
            className="red-gradient-55"
            style={{
              opacity: useSpring(
                useTransform(scrollX, [0, slide(14), slide(15)], [0, 0, 1]),
                { stiffness: stiffness, damping }
              ),
            }}
          />
          <div
            className="cd-header"
            dangerouslySetInnerHTML={{ __html: layers[7].mainContent.html }}
          />
          <Button className="small red glow mt-8" to="/contact">
            Contact Us
          </Button>
        </Slide>
      </div>
      <div className="nav-dots">
        {slides.map((i) => (
          <div className="nav-dot" key={i} onClick={() => scrollTo(i)} />
        ))}
        <motion.div
          className="nav-dot active"
          style={{
            x: useSpring(
              useTransform(scrollX, [0, 10, vWidth * 15], [0, 0, 270]),
              {
                stiffness: stiffness,
                damping,
              }
            ),
          }}
        />
      </div>
    </Styles>
  )
}
